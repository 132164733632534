<!-- See app/views/subscribers/home.html.erb. -->

<template>
  <div class="form sign-up-form">
    <form @submit.prevent="validateAndSignUp">
      <FormTextField
	ref="email-address-field"
	input-mode="email"
	label="Email address"
	lower-case
	note="Case-insensitive"
	trim
	:$v="$v.emailAddress"
	v-model="emailAddress"
	@return="validateAndSignUp"
      />
      <FormSubmit
	ref="sign-up-button"
	label="Sign up"
	:$v="$v"
      />
    </form>
  </div>
</template>

<script>
import FormSubmit from "../forms/FormSubmit";
import FormTextField from "../forms/FormTextField";

//v Includes methods.
import dialogUtilities from "../dialogs/dialogUtilities";

//v Includes methods.
import formUtilities from "../forms/formUtilities";

import {email, required} from "vuelidate/lib/validators";

export default {
  name: "SignUpForm",
  components: {FormSubmit, FormTextField},
  mixins: [dialogUtilities, formUtilities],
  props: {
    signUpForVisitorPath: {type: String, required: true}
  },
  data() { return {emailAddress: null}; },
  validations: {
    emailAddress: {
      required,
      email
    }
  },
  mounted() {
    //v Convenient for debugging in a browser console.
    window.signUpForm = this;
  },
  beforeDestroy() { delete window.signUpForm; },
  methods: {
    validateAndSignUp() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$axios
	.post(this.signUpForVisitorPath, utilities.snakeCaseKeys(this.$data))
	.then(
	  () => {
	    this.info(
	      [
		"Thanks for signing up!",
		"If you ever decide you don’t want to receive newsletters anymore, simply follow the Unsubscribe link in any newsletter you’ve received."
	      ]
	    );
	    this.emailAddress = null;
	    this.$v.emailAddress.$reset();
	  }
	)
	.catch((error) => { this.alertAjaxError(error); });
    }
  }
};
</script>
