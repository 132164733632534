<!-- This component is meant to be invoked by the flicker function in ./dialogUtilities.js only. -->

<template>
  <transition name="fade-fast" @after-enter="queueHide" @after-leave="selfDestruct">
    <div v-if="visible" class="flicker">
      <span v-html="content"/>
    </div>
  </transition>
</template>

<script>
export default {
  //v FlickerComponent rather than Flicker, because single-word component names can be problematic.
  name: "FlickerComponent",
  props: {
    content:  {type: String, required: true},
    duration: {type: Number, required: true}
  },
  data() { return {visible: false}; },
  mounted() { this.visible = true; },
  methods: {
    queueHide() {
      setTimeout(() => { this.visible = false; }, this.duration);
    },
    selfDestruct() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>

<style lang="scss" scoped>
.flicker {
  @apply fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @apply z-40 drop-shadows;
  border-radius: 0.1875rem; /* 3px */
  @apply bg-green-90;
  padding: 0.75rem 0.6875rem; /* 12px 11px */
  @apply text-base leading-base-single;
}
</style>
