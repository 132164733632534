// These functions are simple conveniences. They have no external dependencies except Lodash functions, which are imported one by one as needed.

//v Some Lodash functions are built into many browsers these days. However, they're imported here if they're missing from recent versions of any major browser,
//v according to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/.
import _assign     from "lodash/assign";
import _cloneDeep  from "lodash/cloneDeep";
import _defaults   from "lodash/defaults";
import _forEach    from "lodash/forEach";
import _forOwn     from "lodash/forOwn";
import _includes   from "lodash/includes";
import _isFunction from "lodash/isFunction";
import _isNil      from "lodash/isNil";
import _keys       from "lodash/keys";
import _mapKeys    from "lodash/mapKeys";
import _pick       from "lodash/pick";
import _snakeCase  from "lodash/snakeCase";

export function addClass(klass, element) {
  let classes = element.className.split(/\s+/);
  if (!_includes(classes, klass)) element.className += ` ${klass}`;
};

export function assign(object, ...sources) { return _assign(object, ...sources); };

export function cloneDeep(object) { return _cloneDeep(object); };

//v As in Ruby, where both arrays and hashes are compactable. The argument should be an array or POJO, not something fancy like a map.
export function compact(arrayOrObject) {
  if (Array.isArray(arrayOrObject))
    return arrayOrObject.filter(function(value) { return !_isNil(value); });
  else {
    let result = _cloneDeep(arrayOrObject);
    _forOwn(result, function(value, key) { if (_isNil(value)) delete result[key]; });
    return result;
  }
};

export function defaults(object, ...sources) { return _defaults(object, ...sources); };

export function emptyFunction() {};

//v This differs from Array.prototype.forEach in that it terminates if iteratee returns false.
export function forEach(collection, iteratee) { return _forEach(collection, iteratee); };

export function hasClass(klass, element) {
  let classes = element.className.split(/\s+/);
  return _includes(classes, klass);
};

export function includes(collection, value) { return _includes(collection, value); };

export function isFunction(value) { return _isFunction(value); };

export function keys(object) { return _keys(object); };

export function pick(object, paths) { return _pick(object, paths); };

export function removeClass(klass, element) {
  let
    classes = element.className.split(/\s+/),
    index = classes.indexOf(klass);
  if (index !== -1) {
    classes.splice(index, 1);
    element.className = classes.join(" ");
  }
};

export function snakeCaseKeys(object) {
  return _mapKeys(object, function(value, key) { return _snakeCase(key); });
};

//v Per https://jonsuh.com/blog/detect-the-end-of-css-animations-and-transitions-with-javascript/ and
//v https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/transitionend_event.
export function transitionEndEventName() {
  if (document.body.style.hasOwnProperty("transition"))
    return "transitionend";
  else if (document.body.style.hasOwnProperty("WebkitTransition"))
    return "webkitTransitionEnd";
  else
    return null;
};
