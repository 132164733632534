<template>
  <div class="form-item form-input form-field form-text-area">
    <div class="upper">
      <label class="left" :class="{disabled}">
	<FormLabel :help-text="helpText" :label="label"/>
	<div class="core">
	  <textarea
	    ref="input"
	    :autocomplete="autocomplete"
	    :disabled="disabled"
	    :inputmode="inputMode"
	    :rows="rows"
	    :spellcheck="spellCheck"
	    @blur="rightTrimIfNeeded(); touch()"
	    @input="filterByMaskOrInputModeIfNeeded(); leftTrimIfNeeded(); touchIfInvalid()"
	    @keydown="swallowReturn"
	  >{{ value }}</textarea>
	  <FormErrorTip :$v="$v"/>
	  <div class="decorations">
	    <span v-if="note" class="note" v-html="note"/>
	  </div>
	</div>
      </label>
      <!-- This component has no upper right part. -->
    </div>
    <!-- This component has no lower part. -->
  </div>
</template>

<script>
//v Includes components, mixins, model, props, and methods.
import formField from "./formField";

export default {
  name: "FormTextArea",
  mixins: [formField],
  props: {
    rows: {type: Number, required: true}
  },
  methods: {
    //v Needed in dialog form components to prevent pressing the return key from closing the window.
    swallowReturn(event) { if (event.key === "Enter") event.cancelBubble = true; }
  }
};
</script>

<style lang="scss" scoped>
@import "./formField";
</style>
