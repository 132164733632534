// The code in this file is shared between FormButton [deleted] and FormSubmit.

export default {
  props: {
    classes:             {type: String, required: false},
    ignoreWhenDisabling: {type: Array,  required: false},
    label:               {type: String, required: true },
    $v:                  {type: Object, required: false}
  },
  computed: {
    disabled() {
      if (this.$v)
	for (let param in this.$v.$params)
	  if (!utilities.includes(this.ignoreWhenDisabling, param) && this.$v[param].$error) return true;
      return false;
    }
  }
};
