<!-- This component is meant to be invoked by form items only. -->

<template>
  <div class="form-label">
    <div class="labelAndHelpIcon" @mouseenter="showHelpTipIfPresent" @mouseleave="hideHelpTipIfPresent">
      <span class="label" v-html="label"/>
      <svg v-if="helpTipIsPresent" class="helpIcon" viewBox="0 0 20 20">
	<path d="M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,9.6c5.301,0,9.6-4.298,9.6-9.601C19.6,4.698,15.301,0.4,10,0.4z M9.849,15.599H9.798c-0.782-0.023-1.334-0.6-1.311-1.371c0.022-0.758,0.587-1.309,1.343-1.309l0.046,0.002 c0.804,0.023,1.35,0.594,1.327,1.387C11.18,15.068,10.625,15.599,9.849,15.599z M13.14,9.068c-0.184,0.26-0.588,0.586-1.098,0.983 l-0.562,0.387c-0.308,0.24-0.494,0.467-0.563,0.688c-0.056,0.174-0.082,0.221-0.087,0.576v0.09H8.685l0.006-0.182 c0.027-0.744,0.045-1.184,0.354-1.547c0.485-0.568,1.555-1.258,1.6-1.287c0.154-0.115,0.283-0.246,0.379-0.387 c0.225-0.311,0.324-0.555,0.324-0.793c0-0.334-0.098-0.643-0.293-0.916c-0.188-0.266-0.545-0.398-1.061-0.398 c-0.512,0-0.863,0.162-1.072,0.496c-0.216,0.341-0.325,0.7-0.325,1.067v0.092H6.386L6.39,7.841c0.057-1.353,0.541-2.328,1.435-2.897 C8.388,4.583,9.089,4.4,9.906,4.4c1.068,0,1.972,0.26,2.682,0.772c0.721,0.519,1.086,1.297,1.086,2.311 C13.673,8.05,13.494,8.583,13.14,9.068z"/>
      </svg>
    </div>
    <HelpTip v-if="helpTipIsPresent" :is-visible="helpTipIsVisible" :text="helpText"/>
  </div>
</template>

<script>
import HelpTip from "../tips/HelpTip";

export default {
  name: "FormLabel",
  components: {HelpTip},
  props: {
    helpText: {type: String, required: false},
    label:    {type: String, required: true }
  },
  data() { return {helpTipIsVisible: false}; },
  computed: {helpTipIsPresent() { return !!this.helpText; }},
  methods: {
    hideHelpTipIfPresent() { if (this.helpTipIsPresent) this.helpTipIsVisible = false; },
    showHelpTipIfPresent() { if (this.helpTipIsPresent) this.helpTipIsVisible = true; }
  }
};
</script>

<style lang="scss" scoped>
.form-label {
  @apply relative;

  .labelAndHelpIcon {
    @apply inline-flex items-center justify-start;

    .label {
      @apply font-bold;
      @apply text-base-phone leading-base-single-phone;
      @screen tablet {
	@apply text-base leading-base-single;
      }
      @apply font-sans-narrow;
    }

    .helpIcon {
      margin-left: 0.1875rem; /* 3px */
      height: 0.5625rem; /* 9px */
      width: 0.5625rem; /* 9px */
      @screen tablet {
	margin-left: 0.25rem; /* 4px */
	height: 0.75rem; /* 12px */
	width: 0.75rem; /* 12px */
      }
      fill: theme("colors.gray-60");
    }
  }
}
</style>
