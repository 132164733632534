<!-- See app/views/administrators/invite.html.erb. -->

<template>
  <div class="form invite-form">
    <form @submit.prevent="validateAndInvite">
      <FormTextField
	ref="name-field"
	label="Name"
	trim
	:$v="$v.name"
	v-model="name"
	@return="focus('email-address-field')"
      />
      <FormTextField
	ref="email-address-field"
	input-mode="email"
	label="Email address"
	lower-case
	note="Case-insensitive"
	trim
	:$v="$v.emailAddress"
	v-model="emailAddress"
	@return="focus('note-area')"
      />
      <FormTextArea
	ref="note-area"
	label="Note"
	note="Optional"
	:rows="6"
	trim
	v-model="note"
      />
      <FormSubmit
	ref="invite-button"
	label="Invite"
	:$v="$v"
      />
    </form>
  </div>
</template>

<script>
import FormSubmit from "../forms/FormSubmit";
import FormTextArea from "../forms/FormTextArea";
import FormTextField from "../forms/FormTextField";

//v Includes methods.
import dialogUtilities from "../dialogs/dialogUtilities";

//v Includes methods.
import formUtilities from "../forms/formUtilities";

import {required} from "vuelidate/lib/validators";

export default {
  name: "InviteForm",
  components: {FormSubmit, FormTextArea, FormTextField},
  mixins: [dialogUtilities, formUtilities],
  props: {
    inviteForAdministratorPath: {type: String, required: true},
    invitePath:                 {type: String, required: true}
  },
  data() { return {emailAddress: null, name: null, note: null}; },
  validations: {
    emailAddress: {
      required
    },
    name: {
      required
    }
  },
  mounted() {
    this.$refs["name-field"].focus();
    //v Convenient for debugging in a browser console.
    window.inviteForm = this;
  },
  beforeDestroy() { delete window.inviteForm; },
  methods: {
    validateAndInvite() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$axios
	.post(this.inviteForAdministratorPath, utilities.snakeCaseKeys(utilities.compact(this.$data)))
	.then(() => { Turbolinks.visit(this.invitePath); })
	.catch((error) => { this.alertAjaxError(error); });
    }
  }
};
</script>
