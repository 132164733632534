<!-- See app/views/administrators/log_in.html.erb. -->

<template>
  <div class="form log-in-form">
    <form @submit.prevent="validateAndLogIn">
      <FormTextField
	ref="username-field"
	label="Username"
	trim
	:$v="$v.username"
	v-model="username"
	@return="focus('password-field')"
      />
      <FormPasswordField
	ref="password-field"
	label="Password"
	trim
	:$v="$v.password"
	v-model="password"
	@return="validateAndLogIn"
      />
      <FormSubmit
	ref="log-in-button"
	label="Log in"
	:$v="$v"
      />
    </form>
  </div>
</template>

<script>
import FormPasswordField from "../forms/FormPasswordField";
import FormSubmit from "../forms/FormSubmit";
import FormTextField from "../forms/FormTextField";

//v Includes methods.
import dialogUtilities from "../dialogs/dialogUtilities";

//v Includes methods.
import formUtilities from "../forms/formUtilities";

import {required} from "vuelidate/lib/validators";

export default {
  name: "LogInForm",
  components: {FormPasswordField, FormSubmit, FormTextField},
  mixins: [dialogUtilities, formUtilities],
  props: {
    logInForVisitorPath: {type: String, required: true},
    rootPath:            {type: String, required: true}
  },
  data() { return {username: null, password: null}; },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
  mounted() {
    this.$refs["username-field"].focus();
    //v Convenient for debugging in a browser console.
    window.logInForm = this;
  },
  beforeDestroy() { delete window.logInForm; },
  methods: {
    validateAndLogIn() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$axios
	.post(this.logInForVisitorPath, utilities.snakeCaseKeys(this.$data))
	.then(() => { Turbolinks.visit(this.rootPath); })
	.catch((error) => { this.alertAjaxError(error); });
    }
  }
};
</script>
