<template>
  <div class="form-item form-input form-field form-text-field">
    <div class="upper">
      <label class="left" :class="{disabled}">
	<FormLabel :help-text="helpText" :label="label"/>
	<div class="core">
	  <input
	    ref="input"
	    :class="{'lower-case': lowerCase}"
	    :autocomplete="autocomplete"
	    :disabled="disabled"
	    :inputmode="inputMode"
	    :spellcheck="spellCheck"
	    type="text"
	    :value="value"
	    @blur="lowerCaseIfNeeded(); rightTrimIfNeeded(); touch()"
	    @input="filterByMaskOrInputModeIfNeeded(); leftTrimIfNeeded(); touchIfInvalid()"
	    @keydown="signalReturn"
	  >
	  <FormErrorTip :$v="$v"/>
	  <div class="decorations">
	    <span v-if="note" class="note" v-html="note"/>
	  </div>
	</div>
      </label>
      <!-- This component has no upper right part. -->
    </div>
    <!-- This component has no lower part. -->
  </div>
</template>

<script>
//v Includes components, mixins, model, props, and methods.
import formField from "./formField";

export default {
  name: "FormTextField",
  mixins: [formField],
  props: {
    lowerCase: {type: Boolean, required: false}
  },
  methods: {
    lowerCaseIfNeeded() {
      if (this.lowerCase) {
	let input = this.$refs.input;
	if (input)
	  //^ Paranoia.
	  input.value = input.value.toLowerCase();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./formField";

input.lower-case {
  text-transform: lowercase;
}
</style>
