// This module supports dialogs and selects; see app/webpacker/vue/dialogs/DialogComponent.vue and app/webpacker/vue/forms/formSelect.js. It enables instances
// of such components to disable scrolling of potentially scrollable elements in the background; see app/webpacker/vue/content/ContentComponent.entry.vue.

export default {
  namespaced: true,
  //v Reactivity isn't needed, so mutations can operate on this state using simple assignment rather than Vue.set; see
  //v https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats.
  state: {elements: new Set()},
  mutations: {
    ADD_FREEZE_REQUEST(state, component) {
      let uid = component._uid.toString();
      for (let element of state.elements) {
	if (element.$_scrollableElements_freezeRequests.size === 0) freezeElement(element);
	element.$_scrollableElements_freezeRequests.add(uid);
      }
    },
    DELETE_FREEZE_REQUEST(state, component) {
      let uid = component._uid.toString();
      for (let element of state.elements) {
	let deleted = element.$_scrollableElements_freezeRequests.delete(uid);
	if (deleted && element.$_scrollableElements_freezeRequests.size === 0) thawElement(element);
      }
    },
    ADD_SCROLLABLE_ELEMENT(state, element) {
      element.$_scrollableElements_freezeRequests = new Set();
      state.elements.add(element);
    },
    DELETE_SCROLLABLE_ELEMENT(state, element) {
      state.elements.delete(element);
      //v Paranoia.
      if (element.$_scrollableElements_freezeRequests.size > 0) thawElement(element);
      delete element.$_scrollableElements_freezeRequests;
    }
  },
  actions: {
    addFreezeRequest(context, component) { context.commit("ADD_FREEZE_REQUEST", component); },
    deleteFreezeRequest(context, component) { context.commit("DELETE_FREEZE_REQUEST", component); },
    addScrollableElement(context, element) { context.commit("ADD_SCROLLABLE_ELEMENT", element); },
    deleteScrollableElement(context, element) { context.commit("DELETE_SCROLLABLE_ELEMENT", element); }
  }
};

function freezeElement(element) {
  let
    oldMarginRightString = element.style.marginRight,
    matchData = oldMarginRightString.match(/calc\((.+)\)/),
    newMarginRightString = `${(element === document.body ? window.innerWidth : element.offsetWidth)-element.clientWidth}px`;
  if (matchData) oldMarginRightString = matchData[1];
  if (oldMarginRightString) newMarginRightString = `calc(${oldMarginRightString} + ${newMarginRightString})`;
  element.$_scrollableElements_savedStyle = utilities.pick(element.style, ["marginRight", "overflowY"]);
  utilities.assign(element.style, {marginRight: newMarginRightString, overflowY: "hidden"});
};

function thawElement(element) {
  utilities.assign(element.style, element.$_scrollableElements_savedStyle);
  delete element.$_scrollableElements_savedStyle;
};
