// Rails-generic

import Turbolinks from "turbolinks";
Turbolinks.start();
//v BEGIN: An annoyance of Turbolinks is that although it knows which visits are restorations, triggered via the browser's back and forward buttons, it doesn't
//v bother to communicate this knowledge, through event objects or other means. The following code detects restorations as visits that fire the visit event but
//v not the before-visit event.
document.addEventListener("turbolinks:before-visit", function() { Turbolinks.beforeVisitFired = true; });
document.addEventListener("turbolinks:visit", function() { Turbolinks.visitFired = true; });
document.addEventListener(
  "turbolinks:load",
  function() {
    Turbolinks.visitIsRestoration = !Turbolinks.beforeVisitFired && Turbolinks.visitFired;
    Turbolinks.beforeVisitFired = Turbolinks.visitFired = false;
  }
);
//^ END

// Vue-generic

import Vue from "vue";
Vue.config.devtools = true;

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// Application-specific

import "../stylesheets/application";

import axios from "axios";
Vue.prototype.$axios = axios;
axios.defaults.headers.common["x-requested-with"] = "XMLHttpRequest";
document.addEventListener(
  "turbolinks:load",
  function() {
    let csrfTokenElement = document.querySelector("meta[name='csrf-token']");
    if (csrfTokenElement)
      //^ Paranoia.
      axios.defaults.headers.common["x-csrf-token"] = csrfTokenElement.getAttribute("content");
  }
);

import * as contents from "../javascripts/contents";
window.contents = contents;

const ENTRY_COMPONENTS_CONTEXT = require.context("../vue", true, /\.entry\.vue$/);
utilities.forEach(
  ENTRY_COMPONENTS_CONTEXT.keys(),
  function(path) {
    let options = ENTRY_COMPONENTS_CONTEXT(path).default;
    Vue.component(options.name, options);
  }
);

import store from "../vue/store";

import * as utilities from "../javascripts/utilities";
window.utilities = utilities;
//v Smuggling utilities in via the data option wouldn't be necessary if Vue supported name-spacing methods, like it supports name-spacing data. However, King
//v Evan has decreed that "this is not going to happen"; see https://github.com/vuejs/vue/issues/1470. The point is that utilities must be mixed in, not just
//v tacked onto a global object, to be callable from templates, and referring to a utility should require the prefix "utilities.", as in "utilities.addClass",
//v to indicate where the utility comes from, given utilities is a global rather than local mixin, which otherwise wouldn't need to be mentioned in components
//v using it.
Vue.mixin({data() { return {utilities: Object.freeze(utilities)}; }});

require.context("../images", true, /\.(gif|jpg|png|svg)$/);

document.addEventListener(
  "turbolinks:load",
  function() {
    new Vue(
      {
	//v See app/views/layouts/application.html.erb.
	el: "#vue-mount-point",
	name: "Root",
	store,
	//v As in the vue-turbolinks package, to avoid contention between Vue and Turbolinks caching.
	beforeMount() { this.originalElOuterHtml = this.$el.outerHTML; },
	mounted() {
	  this.$store.dispatch("pageTransitions/addLeaveProcessor", contents.closeIfPresentAndOpen);
	  this.$store.dispatch("pageTransitions/doEnter");
	  this.doingLeave = false;
	  this.handleTurbolinksBeforeVisit =
	    (beforeVisitEvent) => {
	      if (!this.doingLeave) {
		beforeVisitEvent.preventDefault();
  		this.$store.dispatch(
		  "pageTransitions/doLeave",
		  () => {
		    this.doingLeave = true;
		    Turbolinks.visit(beforeVisitEvent.data.url);
		  }
		);
	      }
	    };
	  document.addEventListener("turbolinks:before-visit", this.handleTurbolinksBeforeVisit);
	  document.addEventListener(
	    "turbolinks:visit",
	    () => {
	      //v As in the vue-turbolinks package, to avoid contention between Vue and Turbolinks caching.
	      this.$destroy();
	    },
	    {once: true}
	  );
	},
	beforeDestroy() {
	  this.$store.dispatch("pageTransitions/deleteLeaveProcessor", contents.closeIfPresentAndOpen);
	  document.removeEventListener("turbolinks:before-visit", this.handleTurbolinksBeforeVisit);
	},
	//v As in the vue-turbolinks package, to avoid contention between Vue and Turbolinks caching.
	destroyed() { this.$el.outerHTML = this.originalElOuterHtml; }
      }
    );
  }
);
