import * as utilities from "./utilities";

export function closeIfPresentAndOpen() {
  let transitionEndEventName = utilities.transitionEndEventName();
  if (transitionEndEventName) {
    //^ Paranoia.
    let contentsWrapperElement = document.querySelector("#contents-wrapper");
    if (contentsWrapperElement && utilities.hasClass("is-open", contentsWrapperElement))
      return new Promise(
	function(resolve) {
	  contentsWrapperElement.addEventListener(transitionEndEventName, resolve, {once: true});
	  //v See app/webpacker/stylesheets/contents.scss.
	  utilities.removeClass("is-open", contentsWrapperElement);
	}
      );
  }
  return Promise.resolve();
};

export function toggle() {
  let contentsWrapperElement = document.querySelector("#contents-wrapper");
  if (contentsWrapperElement) {
    //^ Paranoia.
    if (utilities.hasClass("is-open", contentsWrapperElement))
      utilities.removeClass("is-open", contentsWrapperElement);
    else
      utilities.addClass("is-open", contentsWrapperElement);
  }
};
