<template>
  <div class="form-item form-input form-field form-password-field">
    <div class="upper">
      <label class="left" :class="{disabled}">
	<FormLabel :help-text="helpText" :label="label"/>
	<div class="core">
	  <input
	    ref="input"
	    :autocomplete="autocomplete"
	    :disabled="disabled"
	    :type="type"
	    :value="value"
	    @blur="rightTrimIfNeeded(); touch()"
	    @input="leftTrimIfNeeded(); touchIfInvalid()"
	    @keydown="signalReturn"
	  >
	  <FormErrorTip :$v="$v"/>
	  <div class="decorations">
	    <span v-if="getLinkToSetPasswordPath">
	      <a :href="getLinkToSetPasswordPath">Forgot?</a>
	      <span class="text-gray-20">&ensp;|&ensp;</span>
	    </span>
	    <span v-if="note">
	      <span class="note" v-html="note"/>
	      <span class="text-gray-20">&ensp;|&ensp;</span>
	    </span>
	    <button :class="{current: inputIsMasked}" @click.prevent="setInputIsMasked(true)">Hide</button>
	    <span class="text-gray-10">/</span>
	    <button :class="{current: !inputIsMasked}" @click.prevent="setInputIsMasked(false)">Show</button>
	  </div>
	</div>
      </label>
      <!-- This component has no upper right part. -->
    </div>
    <!-- This component has no lower part. -->
  </div>
</template>

<script>
//v Includes components, mixins, model, props, and methods.
import formField from "./formField";

export default {
  name: "FormPasswordField",
  mixins: [formField],
  props: {
    getLinkToSetPasswordPath: {type: String, required: false}
  },
  data() { return {inputIsMasked: true}; },
  computed: {type() { return this.inputIsMasked ? "password" : "text"; }},
  methods: {
    setInputIsMasked(inputWillBeMasked) {
      if (inputWillBeMasked !== this.inputIsMasked) {
	let input = this.$refs.input;
	if (input) {
	  //^ Paranoia.
	  let value = input.value;
	  this.inputIsMasked = inputWillBeMasked;
	  this.$nextTick(function() { input.value = value; });
	}
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./formField";
</style>
