<template>
  <div class="form-button form-submit">
    <button :class="classes" :disabled="disabled" type="submit" v-html="label"/>
  </div>
</template>

<script>
//v Includes props and computed.
import formButton from "./formButton";

export default {name: "FormSubmit", mixins: [formButton]};
</script>

<style lang="scss" scoped>
@import "./formButton";
</style>
