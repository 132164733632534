<template>
  <transition name="fade-fast">
    <div v-if="isVisible" class="help-tip">
      <div class="text" v-html="textHtml"/>
    </div>
  </transition>
</template>

<script>
export default {
  name: "HelpTip",
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    textHtml() {
      const
	BEGIN_P = "<p>",
	END_P = "</p>";
      return BEGIN_P+this.text.replace(/\r?\n\r?\n/g, END_P+BEGIN_P)+END_P;
    }
  }
};
</script>

<style lang="scss" scoped>
.help-tip {
  @apply absolute;
  top: 100%;
  left: 0.1875rem; /* 3px */
  @screen tablet {
    left: 0.25rem; /* 4px */
  }
  @apply z-20 drop-shadows;
  margin-top: 0.1875rem; /* 3px */
  @screen tablet {
    margin-top: 0.25rem; /* 4px */
  }
  /*v BEGIN TODO: Clumsy. */
  width: max-content;
  max-width: 18.75rem; /* 300px */
  @screen tablet {
    max-width: 25rem; /* 400px */
  }
  /*^ END TODO */
  border-radius: 0 0.1875rem 0.1875rem 0.1875rem; /* 0 3px 3px 3px */
  @apply bg-gray-90;
  padding: 0.234375rem 0.421875rem; /* 3.75px 6.75px */
  @apply text-sm-phone leading-sm-phone;
  @screen tablet {
    padding: 0.3125rem 0.5625rem; /* 5px 9px */
    @apply text-sm leading-sm;
  }

  &::after {
    content: "";
    @apply absolute left-0;
    bottom: 100%;
    /*v The top/bottom border width is sqrt(3) times the right/left border width. */
    border-width: 0.365rem 0.211rem; /* 5.846px 3.375px */
    @screen tablet {
      border-width: 0.487rem 0.281rem; /* 7.79px 4.50px */
    }
    @apply border-solid;
    border-color: transparent transparent theme("colors.gray-90") transparent;
  }
}
</style>

<!--v Awkward but needed. Paragraphs are generated dynamically by the computed property textHtml, so unlike statically included paragraphs, which would be -->
<!--v marked with the Vue ID of the component instance, they can't be styled using scoped rules.                                                           -->
<style lang="scss">
.help-tip .text p {
  margin-bottom: 1rem; /* 16px */
  @apply text-sm leading-sm font-sans;

  &:last-child {
    @apply mb-0;
  }
}
</style>
