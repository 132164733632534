// The code in this file is shared among FormCheckbox [deleted], FormDisplay [deleted], FormExtendableSelect [deleted], FormMultiselect [deleted],
// FormNumberField [deleted], FormPasswordField, FormSelect [deleted], FormTextArea [deleted], and FormTextField.

import FormLabel from "./FormLabel";

export default {
  components: {FormLabel},
  props: {
    helpText: {type: String, required: false},
    label:    {type: String, required: true }
  }
};
