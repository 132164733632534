<!-- This component is meant to be invoked by the dialog function in ./dialogUtilities.js only. -->

<template>
  <transition name="fade-fast" @after-enter="addBodyToScrollableElements" @after-leave="selfDestruct" @before-leave="deleteBodyFromScrollableElements">
    <div v-if="visible" class="dialog">
      <div class="scrim">
	<div ref="box" class="box" :class="size">
	  <div v-if="heading" class="header">
	    <div class="heading" v-html="heading"/>
	    <button @click="close(null)"/>
	  </div>
	  <div ref="body" class="body">
	    <div v-if="iconKind" class="icon-container">
	      <!--v Circle-with-cross icon. Inline for speed. -->
	      <svg v-if="iconKind === 'error'" class="icon" viewBox="0 0 40 40">
		<path d="M20,0.8 C9.394,0.8 0.798,9.396 0.798,20 C0.798,30.606 9.394,39.202 20,39.202 C30.602,39.202 39.2,30.606 39.2,20 C39.2,9.396 30.602,0.8 20,0.8 Z M30.6422222,26.8022222 L26.8,30.6444444 L20,23.8422222 L13.1977778,30.6422222 L9.35555556,26.8 L16.16,20 L9.35777778,13.1977778 L13.2,9.35777778 L20,16.1577778 L26.8022222,9.35555556 L30.6444444,13.1977778 L23.84,20 L30.6422222,26.8022222 Z"/>
	      </svg>
	      <!--v Info-with-circle icon. Inline for speed. -->
	      <svg v-else-if="iconKind === 'info'" class="icon" viewBox="0 0 40 40">
		<path d="M20,0.8 C9.394,0.8 0.798,9.396 0.798,20 C0.798,30.606 9.394,39.202 20,39.202 C30.602,39.202 39.2,30.606 39.2,20 C39.2,9.396 30.602,0.8 20,0.8 Z M21.792,7.732 C23.664,7.732 24.214,8.818 24.214,10.06 C24.214,11.61 22.974,13.044 20.856,13.044 C19.084,13.044 18.24,12.154 18.292,10.68 C18.292,9.438 19.33,7.732 21.792,7.732 Z M16.996,31.5 C15.716,31.5 14.782,30.722 15.676,27.312 L17.142,21.262 C17.396,20.294 17.438,19.906 17.142,19.906 C16.76,19.906 15.098,20.574 14.118,21.234 L13.48,20.188 C16.59,17.59 20.166,16.066 21.696,16.066 C22.976,16.066 23.188,17.578 22.55,19.906 L20.87,26.266 C20.572,27.39 20.7,27.778 20.998,27.778 C21.382,27.778 22.638,27.314 23.874,26.34 L24.598,27.312 C21.572,30.336 18.274,31.5 16.996,31.5 Z"/>
	      </svg>
	      <!--v Help-with-circle icon. Inline for speed. -->
	      <svg v-else-if="iconKind === 'question'" class="icon" viewBox="0 0 40 40">
		<path d="M20,0.8 C9.396,0.8 0.8,9.396 0.8,20 C0.8,30.604 9.396,39.2 20,39.2 C30.602,39.2 39.2,30.604 39.2,19.998 C39.2,9.396 30.602,0.8 20,0.8 Z M19.698,31.198 L19.596,31.198 C18.032,31.152 16.928,29.998 16.974,28.456 C17.018,26.94 18.148,25.838 19.66,25.838 L19.752,25.842 C21.36,25.888 22.452,27.03 22.406,28.616 C22.36,30.136 21.25,31.198 19.698,31.198 Z M26.28,18.136 C25.912,18.656 25.104,19.308 24.084,20.102 L22.96,20.876 C22.344,21.356 21.972,21.81 21.834,22.252 C21.722,22.6 21.67,22.694 21.66,23.404 L21.66,23.584 L17.37,23.584 L17.382,23.22 C17.436,21.732 17.472,20.852 18.09,20.126 C19.06,18.99 21.2,17.61 21.29,17.552 C21.598,17.322 21.856,17.06 22.048,16.778 C22.498,16.156 22.696,15.668 22.696,15.192 C22.696,14.524 22.5,13.906 22.11,13.36 C21.734,12.828 21.02,12.564 19.988,12.564 C18.964,12.564 18.262,12.888 17.844,13.556 C17.412,14.238 17.194,14.956 17.194,15.69 L17.194,15.874 L12.772,15.874 L12.78,15.682 C12.894,12.976 13.862,11.026 15.65,9.888 C16.776,9.166 18.178,8.8 19.812,8.8 C21.948,8.8 23.756,9.32 25.176,10.344 C26.618,11.382 27.348,12.938 27.348,14.966 C27.346,16.1 26.988,17.166 26.28,18.136 Z"/>
	      </svg>
	      <!--v Warning icon. Inline for speed. -->
	      <svg v-else class="icon" viewBox="0 0 40 40">
		<path d="M20,0.8 C30.602,0.8 39.2,9.396 39.2,20 C39.2,30.606 30.602,39.202 20,39.202 C9.394,39.202 0.798,30.606 0.798,20 C0.798,9.396 9.394,0.8 20,0.8 Z M31.6859922,27.4966667 L20.5522422,6.24466667 C20.3972422,5.96994444 20.1097422,5.8 19.7972422,5.8 C19.4834922,5.8 19.1959922,5.96994444 19.0422422,6.24466667 L7.90974219,27.4966667 C7.75849219,27.7662778 7.76099219,28.0985 7.91599219,28.3655556 C8.07224219,28.6351667 8.35599219,28.8 8.66349219,28.8 L30.9309922,28.8 C31.2372422,28.8 31.5234922,28.6351667 31.6784922,28.3655556 C31.8334922,28.0985 31.8359922,27.7662778 31.6859922,27.4966667 Z M21.298,26.2455556 L18.798,26.2455556 L18.798,23.69 L21.298,23.69 L21.298,26.2455556 Z M21.298,21.7755556 L18.798,21.7755556 L18.798,13.47 L21.298,13.47 L21.298,21.7755556 Z"/>
	      </svg>
	    </div>
	    <div v-if="contentMainString" class="content-string-container" v-html="paragraphedContentString"/>
	    <div v-else class="content-component-instance-container">
	      <component :is="contentComponentOptions" ref="content-component-instance" v-bind="contentComponentPropsData" @close="close($event)"/>
	    </div>
	  </div>
	  <div class="footer">
	    <button v-if="kind === 'alert'" @click="close(true)" v-html="okButtonLabel"/>
	    <button v-if="kind === 'confirm'" @click="close(true)" v-html="yesButtonLabel"/>
	    <button v-if="kind === 'confirm'" class="no" @click="close(false)" v-html="noButtonLabel"/>
	  </div>
	</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  //v DialogComponent rather than Dialog, because single-word component names can be problematic.
  name: "DialogComponent",
  props: {
    beforeClose:               {type: Function, required: true },
    contentComponentOptions:   {type: Object,   required: false},
    contentComponentPropsData: {type: Object,   required: false},
    contentMainString:         {type: String,   required: false},
    contentNoteString:         {type: String,   required: false},
    heading:                   {type: String,   required: false},
    //v "error", "info", "question", or "warning".
    iconKind:                  {type: String,   required: false},
    //v "alert" or "confirm".
    kind:                      {type: String,   required: true },
    noButtonLabel:             {type: String,   required: true },
    okButtonLabel:             {type: String,   required: true },
    resolve:                   {type: Function, required: true },
    //v "large" or "small".
    size:                      {type: String,   required: true },
    yesButtonLabel:            {type: String,   required: true }
  },
  data() { return {visible: false}; },
  computed: {
    paragraphedContentString() {
      const
	BEGIN_MAIN_P = "<p>",
	BEGIN_NOTE_P = "<p class='note'>",
	END_P = "</p>";
      let
	paragraphedContentMainString = BEGIN_MAIN_P+this.contentMainString.replace(/\r?\n\r?\n/g, END_P+BEGIN_MAIN_P)+END_P,
	paragraphedContentNoteString = this.contentNoteString ? BEGIN_NOTE_P+this.contentNoteString.replace(/\r?\n\r?\n/g, END_P+BEGIN_NOTE_P)+END_P : "";
      return paragraphedContentMainString+paragraphedContentNoteString;
    }
  },
  mounted() {
    utilities.forEach(document.querySelectorAll("input"), function(inputEl) { inputEl.blur(); });
    this.visible = true;
    this.$store.dispatch("scrollableElements/addFreezeRequest", this);
    this.handleReturnAndEscape =
      (event) => {
	if (!this.$el.nextElementSibling) {
	  //^ TODO: Brittle, in that this assumes a next element sibling is a newer dialog, which works because the dialog function appends dialogs to the body
	  //^ element, but is there a better way?
	  if (event.key === "Enter")
	    this.close(true);
	  else if (event.key === "Escape")
	    this.close(null);
	}
      };
    document.addEventListener("keydown", this.handleReturnAndEscape);
  },
  beforeDestroy() {
    this.$store.dispatch("scrollableElements/deleteFreezeRequest", this);
    document.removeEventListener("keydown", this.handleReturnAndEscape);
  },
  methods: {
    addBodyToScrollableElements() { this.$store.dispatch("scrollableElements/addScrollableElement", this.$refs.body); },
    close(signal) { if (this.beforeClose(signal, this.resolve, this.$refs["content-component-instance"])) this.visible = false; },
    deleteBodyFromScrollableElements() { this.$store.dispatch("scrollableElements/deleteScrollableElement", this.$refs.body); },
    selfDestruct() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog {
  @apply relative z-40;

  .scrim {
    @apply fixed top-0 left-0 flex items-center justify-center h-full w-full bg-black-opacity-50;

    .box {
      @apply border border-gray-25;
      border-radius: 0.1875rem; /* 3px */
      @apply bg-white;

      &.large {
	width: 42rem; /* 672px */
      }

      &.small {
	width: 25rem; /* 400px */
      }

      .header {
	@apply flex items-center justify-between;
	height: 3.4375rem; /* 55px */
	@apply border-b border-gray-75;
	border-radius: 2px 2px 0 0;
	padding: 0 0.9375rem; /* 0 15px */
	@apply bg-gray-98;

	.heading {
	  @apply font-bold text-lg leading-lg-single;
	}

	button {
	  width: 13px;
	  padding-top: 1px;
	  @apply text-gray-10;
	  font-family: e-icons;

	  &::before {
	    content: "\e745";
	  }

	  &:hover {
	    /*v gray-20 is a lot too dark. */
	    @apply text-gray-45;
	  }
	}
      }

      .body {
	@apply flex items-start justify-start;
	max-height: 67vh;
	@apply overflow-auto;
	padding: 1.4375rem 0.9375rem 1rem; /* 23px 15px 16px */

	.icon-container {
	  margin-bottom: 0.5rem; /* 8px */
	  padding-right: 0.9375rem; /* 15px */

	  .icon {
	    @apply flex-shrink-0;
	    height: 3rem; /* 48px */
	    width: 3rem; /* 48px */
	    fill: theme("colors.gray-10");
	  }
	}

	.content-component-instance-container {
	  @apply w-full;
	}
      }

      .footer {
	@apply flex items-center justify-end;
	height: 3.4375rem; /* 55px */
	@apply border-t border-gray-75;
	border-radius: 0 0 2px 2px;
	@apply bg-gray-98;

	button {
	  height: 2rem; /* 32px */
	  width: 6rem; /* 96px */
	  margin-right: 0.625rem; /* 10px */
	  @apply rounded-full bg-theme-button text-white font-bold text-base leading-base-single;

	  &:hover {
	    @apply bg-theme-button-hover;
	  }

	  &.no {
	    @apply bg-gray-10;

	    &:hover {
	      /*v gray-20 is too dark. */
	      @apply bg-gray-30;
	    }
	  }
	}
      }
    }
  }
}
</style>

<!--v Awkward but needed. Paragraphs are generated dynamically by the computed property paragraphedContentString, so unlike statically included paragraphs, -->
<!--v which would be marked with the Vue ID of the component instance, they can't be styled using scoped rules.                                             -->
<style lang="scss">
.dialog .box .body .content-string-container p {
  margin-bottom: 1rem; /* 16px */
  @apply text-base leading-base;

  &:last-child {
    margin-bottom: 0.5rem; /* 8px */
  }

  &.note {
    @apply text-sm leading-sm;
  }
}
</style>
