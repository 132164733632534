// This module supports calling three kinds of handler when a page transition is initiated. One kind, called a gatekeeper, determines whether the transition
// proceeds; for example, a gatekeeper may display a dialog asking the user to confirm the transition. A second kind, called a leave-processor, eases into the
// transition in some way; for example, a leave-processor may animate departing from the current page. The third kind, called an enter-processor, eases out of
// the transition in some way; for example, an enter-processor may animate arriving at the next page. Typically, all three kinds of handler involve asynchronous
// operations, such as dialogs and animations. The transition itself shouldn't proceed until gatekeepers and leave-processors finish and should complete before
// enter-processors start. (Usually, it doesn't matter that enter-processors involve asynchronous operations, because nothing is waiting for them to finish.
// Often, however, enter-processors are mirror images of leave-processors, as in the case of animating page arrivals and departures, so it's perspicuous to
// formalize the two kinds of processor together.)
//
// To use the module, a component should dispatch the addGatekeeper, addLeaveProcessor, or addEnterProcessor action, passing a gatekeeper or processor, which
// should be a function that returns a promise. The promise returned by a gatekeeper should resolve or reject if a page transition should or shouldn't proceed,
// respectively. The promise returned by a processor should resolve when the operation instigated by the processor finishes; it should never reject. In neither
// case is the value with which the promise resolves or rejects significant.
//
// When a page transition is initiated, it should be intercepted and canceled; for example, under Turbolinks, a listener for the turbolinks:before-visit event
// should call the preventDefault method on the event object. The doLeave action should be dispatched and passed a function that restarts the transition when
// called; for example, under Turbolinks, the function should call the Turbolinks.visit function, passing the appropriate URL. doLeave first calls gatekeepers
// sequentially, in the order they were added; the promise returned by one gatekeeper must resolve before the next gatekeeper is called. If all these promises
// resolve rather than reject, then doLeave next calls leave-processors in parallel. Once all promises returned by leave-processors resolve, doLeave calls the
// passed function, and the transition proceeds.
//
// When a page transition completes, the doEnter action should be dispatched and optionally passed a function; for example, under Turbolinks, a listener for the
// turbolinks:load event should dispatch the action. doEnter calls enter-processors in parallel. Once all promises returned by enter-processors resolve, doEnter
// calls the passed function, if any.
//
// To support elaborate user-interface choreography, such as successive animations of different parts of a page, leave- or enter-processors can be divided into
// groups. Each group has a distinct number, processors in the same group are called in parallel, and different groups are processed sequentially, in ascending
// order by number. Simply passing a processor to addLeaveProcessor or addEnterProcessor places the processor in group 1, whereas passing an object of the form
// {group: n, processor: f} places the processor in group n.
//
// If the store persists across page transitions, or merely as a matter of good code hygiene, a component that adds a gatekeeper or processor should dispatch
// the deleteGatekeeper, deleteLeaveProcessor, or deleteEnterProcessor action to remove it when the component is destroyed.

export default {
  namespaced: true,
  //v Reactivity isn't needed, so mutations can operate on this state using simple assignment rather than Vue.set; see
  //v https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats.
  state: {
    enterProcessors: {},
    gatekeepers: [],
    leaveProcessors: {}
  },
  mutations: {
    ADD_ENTER_PROCESSOR(state, data) { addProcessor(data, state.enterProcessors); },
    ADD_GATEKEEPER(state, gatekeeper) { state.gatekeepers.push(gatekeeper); },
    ADD_LEAVE_PROCESSOR(state, data) { addProcessor(data, state.leaveProcessors); },
    DELETE_ENTER_PROCESSOR(state, data) { deleteProcessor(data, state.enterProcessors); },
    DELETE_GATEKEEPER(state, gatekeeperToDelete) {
      state.gatekeepers = state.gatekeepers.filter(function(gatekeeper) { return gatekeeper !== gatekeeperToDelete; });
    },
    DELETE_LEAVE_PROCESSOR(state, data) { deleteProcessor(data, state.leaveProcessors); }
  },
  actions: {
    addEnterProcessor(context, data) { context.commit("ADD_ENTER_PROCESSOR", data); },
    addGatekeeper(context, gatekeeper) { context.commit("ADD_GATEKEEPER", gatekeeper); },
    addLeaveProcessor(context, data) { context.commit("ADD_LEAVE_PROCESSOR", data); },
    deleteEnterProcessor(context, data) { context.commit("DELETE_ENTER_PROCESSOR", data); },
    deleteGatekeeper(context, gatekeeper) { context.commit("DELETE_GATEKEEPER", gatekeeper); },
    deleteLeaveProcessor(context, data) { context.commit("DELETE_LEAVE_PROCESSOR", data); },
    doEnter(context, next) {
      let handlers = [];
      utilities.forEach(
	utilities.keys(context.state.enterProcessors).sort(),
	function(group) {
	  handlers.push(
	    function() {
	      return Promise.all(context.state.enterProcessors[group].map(function(processor) { return processor(); }));
	    }
	  );
	}
      );
      if (next) handlers.push(next);
      handlers.reduce(function(promiseChain, handler) { return promiseChain.then(handler); }, Promise.resolve());
    },
    doLeave(context, proceed) {
      let handlers = context.state.gatekeepers.slice();
      utilities.forEach(
	utilities.keys(context.state.leaveProcessors).sort(),
	function(group) {
	  handlers.push(
	    function() {
	      return Promise.all(context.state.leaveProcessors[group].map(function(processor) { return processor(); }));
	    }
	  );
	}
      );
      handlers.push(proceed);
      handlers.reduce(function(promiseChain, handler) { return promiseChain.then(handler); }, Promise.resolve())
	//v Else rejection by a gatekeeper elicits an "uncaught" warning.
	.catch(utilities.emptyFunction);
    }
  }
};

function addProcessor(data, processors) {
  let group = getGroup(data);
  if (!(group in processors)) processors[group] = [];
  processors[group].push(getProcessor(data));
};

function deleteProcessor(data, processors) {
  let group = getGroup(data);
  if (group in processors) {
    //^ Paranoia.
    let processorToDelete = getProcessor(data);
    processors[group] = processors[group].filter(function(processor) { return processor !== processorToDelete; });
    if (processors[group].length === 0) delete processors[group];
  }
};

function getGroup(data) { return utilities.isFunction(data) ? 1 : data.group; };

function getProcessor(data) { return utilities.isFunction(data) ? data : data.processor; };
