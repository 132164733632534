// The code in this file is shared among FormCheckbox [deleted], FormExtendableSelect [deleted], FormMultiselect [deleted], FormNumberField [deleted],
// FormPasswordField, FormSelect [deleted], FormTextArea [deleted], and FormTextField.

//v Includes components and props.
import formItem from "./formItem";

export default {
  mixins: [formItem],
  model: {event: "touch"},
  props: {
    disabled: {type: Boolean, required: false}
  }
};
