<!-- See app/views/layouts/_content.html.erb. -->

<template>
  <div class="content">
    <slot/>
  </div>
</template>

<script>
export default {
  //v ContentComponent rather than Content, because single-word component names can be problematic.
  name: "ContentComponent",
  mounted() {
    let
      contentWrapperElement = document.querySelector("#content-wrapper"),
      scrollbarWidth = contentWrapperElement.offsetWidth-contentWrapperElement.clientWidth,
      transitionEndEventName = utilities.transitionEndEventName();
    //v BEGIN: Clumsy but needed.
    if (scrollbarWidth > 0) {
      let headerWrapperElement = document.querySelector("#header-wrapper");
      headerWrapperElement.style["padding-right"] = scrollbarWidth+"px";
    }
    //^ END
    this.$store.dispatch("scrollableElements/addScrollableElement", contentWrapperElement);
    this.fadeInContent =
      function() {
	return new Promise(
	  function(resolve) {
	    //v Delay is needed, for reasons unclear.
	    setTimeout(function() { utilities.addClass("is-visible", contentWrapperElement); });
	    if (transitionEndEventName)
	      contentWrapperElement.addEventListener(transitionEndEventName, resolve, {once: true});
	    else
	      resolve();
	  }
	);
      };
    this.$store.dispatch("pageTransitions/addEnterProcessor", this.fadeInContent);
    this.fadeOutContent =
      function() {
	return new Promise(
	  function(resolve) {
	    utilities.removeClass("is-visible", contentWrapperElement);
	    if (transitionEndEventName)
	      contentWrapperElement.addEventListener(transitionEndEventName, resolve, {once: true});
	    else
	      resolve();
	  }
	);
      };
    this.$store.dispatch("pageTransitions/addLeaveProcessor", this.fadeOutContent);
  },
  beforeDestroy() {
    let contentWrapperElement = document.querySelector("#content-wrapper");
    this.$store.dispatch("scrollableElements/deleteScrollableElement", contentWrapperElement);
    this.$store.dispatch("pageTransitions/deleteEnterProcessor", this.fadeInContent);
    this.$store.dispatch("pageTransitions/deleteLeaveProcessor", this.fadeOutContent);
  }
};
</script>

<style lang="scss" scoped>
.content {
  @apply mx-auto;
  /*v Should match #header. See ../../stylesheets/header.scss. */
  max-width: 50rem; /* 800px */
  /*v Horizontal padding should match #header. See ../../stylesheets/header.scss. */
  padding: 1.125rem 1.3125rem 0.5625rem; /* 18px 21px 9px */
  @screen tablet {
    /*v Right padding should match right margin of .image. See ../../stylesheets/home.scss. */
    padding: 1.5rem 1.75rem 0.75rem; /* 24px 28px 12px */
  }
}
</style>
