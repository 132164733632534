// The code in this file is shared among FormNumberField [deleted], FormPasswordField, FormTextArea [deleted], and FormTextField.

import FormErrorTip from "./FormErrorTip";

//v Includes components, mixins, model, and props.
import formInput from "./formInput";

export default {
  components: {FormErrorTip},
  mixins: [formInput],
  props: {
    autocomplete: {type: String,           required: false},
    //v Relevant to FormTextArea and FormTextField only, not FormNumberField or FormPasswordField.
    inputMode:    {type: String,           required: false},
    //v Relevant to FormTextArea and FormTextField only, not FormNumberField or FormPasswordField. If both inputMode and mask are given, then filtering by mask
    //v overrides filtering by inputMode.
    mask:         {type: RegExp,           required: false},
    note:         {type: [String, Array],  required: false},
    //v Relevant to FormTextArea and FormTextField only, not FormNumberField or FormPasswordField.
    spellCheck:   {type: Boolean,          required: false},
    //v Relevant to FormPasswordField, FormTextArea, and FormTextField only, not FormNumberField.
    trim:         {type: Boolean,          required: false},
    value:        {type: [Number, String], required: false},
    $v:           {type: Object,           required: false}
  },
  methods: {
    blur() {
      let input = this.$refs.input;
      if (input)
	//^ Paranoia.
	input.blur();
    },
    //v Relevant to FormTextArea and FormTextField only, not FormNumberField or FormPasswordField.
    filterByMaskOrInputModeIfNeeded() {
      if (this.mask || this.inputMode === "decimal" || this.inputMode === "numeric") {
	let input = this.$refs.input;
	if (input) {
	  //^ Paranoia.
	  let
	    value = input.value,
	    //v If inputmode is "decimal", then iOS 12.2 offers [\d\.], while Chrome for Android offers a superset. If inputmode is "numeric", then Chrome for
	    //v Android offers [-\d\., ], while iOS 12.2 offers a superset. See https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/.
	    regExp = this.mask || (this.inputMode === "decimal" ? /[^\d\.]/ : /[^-\d\., ]/),
	    filteredValue = value.replace(regExp, "");
	  if (filteredValue !== value) {
	    //v Note that the filtered characters should precede the cursor, whether this method is triggered by typing or pasting.
	    let newCursorPosition = input.selectionStart-(value.length-filteredValue.length);
	    input.value = filteredValue;
	    input.setSelectionRange(newCursorPosition, newCursorPosition);
	  }
	}
      }
    },
    focus() {
      let input = this.$refs.input;
      if (input)
	//^ Paranoia.
	input.focus();
    },
    //v Relevant to FormPasswordField, FormTextArea, and FormTextField only, not FormNumberField.
    leftTrimIfNeeded() {
      if (this.trim) {
	let input = this.$refs.input;
	if (input) {
	  //^ Paranoia.
	  let
	    value = input.value,
	    leftTrimmedValue = value.trimStart();
	  if (leftTrimmedValue !== value) {
	    let newCursorPosition = input.selectionStart-(value.length-leftTrimmedValue.length);
	    input.value = leftTrimmedValue;
	    input.setSelectionRange(newCursorPosition, newCursorPosition);
	  }
	}
      }
    },
    //v Relevant to FormPasswordField, FormTextArea, and FormTextField only, not FormNumberField.
    rightTrimIfNeeded() {
      if (this.trim) {
	let input = this.$refs.input;
	if (input)
	  //^ Paranoia.
	  input.value = input.value.trimEnd();
      }
    },
    //v Relevant to FormNumberField, FormPasswordField, and FormTextField only, not FormTextArea.
    signalReturn(event) {
      if (event.key === "Enter") {
	this.blur();
	this.$emit("return");
      }
    },
    //v Overridden by FormNumberField.
    touch() {
      let input = this.$refs.input;
      if (input)
	//^ Paranoia.
	this.$emit("touch", input.value || null);
    },
    touchIfInvalid() { if (this.$v && this.$v.$error) this.touch(); }
  }
};
