<!-- This component is meant to be invoked by form inputs only. -->

<template>
  <transition name="fade-fast">
    <div v-if="$v && $v.$error" class="form-error-tip" :class="{right}">
      <span v-if="'email' in $v && !$v.email" v-html="'This should be an email address.'"/>
      <span v-else-if="'required' in $v && !$v.required" v-html="'This is required.'"/>
    </div>
  </transition>
</template>

<script>
export default {
  name: "FormErrorTip",
  props: {
    right: {type: Boolean, required: false},
    $v:    {type: Object,  required: false}
  }
};
</script>

<style lang="scss" scoped>
.form-error-tip {
  @apply absolute;
  top: 100%;
  left: 0.1875rem; /* 3px */
  @screen tablet {
    left: 0.25rem; /* 4px */
  }
  @apply z-20 drop-shadows;
  margin-top: 0.1875rem; /* 3px */
  @screen tablet {
    margin-top: 0.25rem; /* 4px */
  }
  border-radius: 0 0.1875rem 0.1875rem 0.1875rem; /* 0 3px 3px 3px */
  @apply bg-red-80;
  padding: 0.46875rem 0.421875rem; /* 7.5px 6.75px */
  @apply text-sm-phone leading-sm-single-phone;
  @screen tablet {
    padding: 0.625rem 0.5625rem; /* 10px 9px */
    @apply text-sm leading-sm-single;
  }

  &::after {
    content: "";
    @apply absolute left-0;
    bottom: 100%;
    /*v The top/bottom border width is sqrt(3) times the right/left border width. */
    border-width: 0.365rem 0.211rem; /* 5.846px 3.375px */
    @screen tablet {
      border-width: 0.487rem 0.281rem; /* 7.79px 4.50px */
    }
    @apply border-solid;
    border-color: transparent transparent theme("colors.red-80") transparent;
  }

  &.right {
    @apply left-auto;
    right: 0.1875rem; /* 3px */
    @screen tablet {
      right: 0.25rem; /* 4px */
    }
    border-radius: 0.1875rem 0 0.1875rem 0.1875rem; /* 3px 0 3px 3px */

    &::after {
      @apply left-auto right-0;
    }
  }
}
</style>
