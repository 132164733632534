import Vue from "vue";

import DialogComponent from "./DialogComponent";
import FlickerComponent from "./FlickerComponent";

const
  DialogConstructor = Vue.extend(DialogComponent),
  FlickerConstructor = Vue.extend(FlickerComponent);

export default {
  methods: {
    alertAjaxError(ajaxError, options) {
      //^ ajaxError should be an error object from axios.
      return this.error(
	(
	  (ajaxError && ajaxError.response && ajaxError.response.data && ajaxError.response.data.message)
	  ? ajaxError.response.data.message
	  : "Oops! Please try again."
	),
	options
      );
    },
    dialog(content, kind, options = {}) {
      //^ content should be a string, an array of two strings, or an object specifying a Vue component instance. In the first two cases, the string or strings
      //^ may contain HTML specifying inline elements but shouldn't contain HTML specifying block elements, and any user-supplied content within them should be
      //^ HTML-escaped to thwart injection attacks. In the last case, the object should have properties options and propsData with values the options object of
      //^ the component and the props-data object for the instance, respectively.
      //^ kind should be "alert" or "confirm".
      //^ Valid options include beforeClose, heading, iconKind, noButtonLabel, okButtonLabel, size, and yesButtonLabel.
      //^ If given, options.beforeClose should be of the form function(signal, resolve, contentComponentInstance), where signal is true if kind is "alert" and
      //^ the  OK button is clicked or if kind is "confirm" and the Yes button is clicked, false if kind is "confirm" and the No button is clicked, or null if
      //^ the close icon is clicked; resolve is the resolver of the promise below; and contentComponentInstance is the Vue component instance, if any, specified
      //^ by content.
      //^ If options.beforeClose returns falsey, then closing is aborted.
      //^ If given, options.iconKind should be "error", "info", "question", or "warning".
      //^ options.noButtonLabel and yesButtonLabel are applicable if kind is "confirm", and options.okButtonLabel is applicable if kind is "alert".
      //^ If given, options.size should be "large" or "small".
      options = utilities.defaults(
	options,
	{
	  beforeClose:
	    function(signal, resolve) {
	      resolve(signal);
	      return true;
	    },
	  noButtonLabel: "No",
	  okButtonLabel: "OK",
	  size: "small",
	  yesButtonLabel: "Yes"
	}
      );
      let
	contentMainString,
	contentNoteString,
	contentComponentOptions,
	contentComponentPropsData;
      if (typeof content === "string")
	contentMainString = content;
      else if (content instanceof Array) {
	contentMainString = content[0];
	contentNoteString = content[1];
      } else {
	contentComponentOptions = content.options;
	contentComponentPropsData = content.propsData;
      }
      return new Promise(
	(resolve) => {
	  let dialog = new DialogConstructor(
	    {
	      parent: this,
	      propsData: {
		beforeClose: options.beforeClose,
		contentComponentOptions,
		contentComponentPropsData,
		contentMainString,
		contentNoteString,
		heading: options.heading,
		iconKind: options.iconKind,
		kind,
		noButtonLabel: options.noButtonLabel,
		okButtonLabel: options.okButtonLabel,
		resolve,
		size: options.size,
		yesButtonLabel: options.yesButtonLabel
	      }
	    }
	  );
	  dialog.$mount();
	  document.body.appendChild(dialog.$el);
	}
      );
    },
    error(content, options) { return this.dialog(content, "alert", utilities.defaults({iconKind: "error"}, options)); },
    flicker(content, options = {}) {
      //^ Valid option is duration.
      //^ If given, options.duration should be in milliseconds.
      options = utilities.defaults(options, {duration: 3000});
      let flicker = new FlickerConstructor(
	{
	  parent: this,
	  propsData: {content, duration: options.duration}
	}
      );
      flicker.$mount();
      document.body.appendChild(flicker.$el);
    },
    info(content, options) { return this.dialog(content, "alert", utilities.defaults({iconKind: "info"}, options)); },
    question(content, options) { return this.dialog(content, "confirm", utilities.defaults({iconKind: "question"}, options)); },
    warning(content, options) { return this.dialog(content, "confirm", utilities.defaults({iconKind: "warning"}, options)); }
  }
};
